import Heading from '../Atoms/Headings/Heading'
import HeadingH2 from '../Atoms/Headings/HeadingH2'

function AboutZeniq() {
  return (
    <div className='container mx-auto px-[30px] 640px:max-w-[100%]'>
        <div className='w-[80%] flex-col flex gap-8 1024px:w-[100%] 480px:w-[90%] 1024px:mx-auto 1024px:text-center 1024px:gap-3 767px:gap-2'>
            <Heading level='2'>about zeniq</Heading>
            <p className='font-HeronSans text-[18px] font-[300]'>ZENIQ aims to be a leader in the delivery of secure blockchain-based solutions, offering the highest quality of innovative, leading edge technologies built on secure infrastructure and developed by leading industry practitioners and partners.<br></br><br></br>Some key components of ZENIQ’s Smart Chain are the ZENIQ Hub and the NOMO App: The hub is robust, secure, and a unique hardware device with a seamless and secure self-controlled ecosystem for safeguarding digital assets. The advanced infrastructure isolates the KeyGuard from the device’s main computer, thereby protecting the private key from unauthorized access. The NOMO App streamlines your digital experience. With multi-blockchain support, including ZENIQ Smart Chain, Ethereum, Bitcoin, and Binance Smart Chain, it simplifies asset management. Plus, explore NOMO plugins to supercharge your app with additional features.<br></br><br></br>                ZENIQ is poised to evangelize the blockchain landscape with innovative products and services. <br></br>Find out more about the ZENIQ ecosystem by visiting<span><a className='text-[#87FF4F]' href="www.zeniq.com"> www.zeniq.com.</a></span></p>
        </div>
    </div>
  )
}

export default AboutZeniq