import GlobalContent from "../Molecules/Content/GlobalContent"
import Heading from "../Atoms/Headings/Heading";
import Venue from "../Vanue/Venue";
import ytVideos2022 from "../../assets/videos/videos2022.json";
import ytVideos2023 from "../../assets/videos/videos2023.json";
import SlideShow from "../SlideShow";
import pastedition_2023_1 from '../../assets/Assets_home/pastedition_2023_1.jpg';
import pastedition_2023_2 from 'assets/Assets_home/pastedition_2023_2.jpg';
import pastedition_2023_3 from 'assets/Assets_home/pastedition_2023_3.jpg';


const pastEditions = [
    {
        year: "2023",
        html:
            <div className="flex flex-col justify-between gap-16 h-[1400px] 1600px:h-[1100px] 1280px:h-[900px] 1024px:h-[700px] 840px:h-[1400px] 640px:h-[1000px]">
                {/*<div className="flex gap-8 1280px:gap-6 1024px:gap-4 640px:gap-8 h-[1300px] 1440px:h-[550px] 1280px:h-[510px] 1024px:h-[370px] 640px:h-auto 640px:flex-col">*/}
                <div className="840px:hidden">
                    <div className="h-1/2 flex gap-8 1280px:gap-6 1024px:gap-4 640px:gap-8 640px:h-auto 840px:flex-col 840px:gap-10">
                        <div className="w-1/2 640px:w-full 640px:h-[340px] 840px:w-full">
                            <img alt="image" className="h-full bg-no-repeat object-contain bg-center" src={pastedition_2023_2.src}></img>
                        </div>
                        <div className="w-1/2 640px:w-full 640px:h-[340px] 840px:w-full">
                            <img alt="image" className="h-full bg-no-repeat object-contain bg-center" src={pastedition_2023_3.src}></img>
                        </div>
                    </div>
                    <div className="flex justify-center h-1/2 640px:w-full 640px:h-[340px]">
                        <img alt="image" className="h-full bg-no-repeat object-contain" src={pastedition_2023_1.src}></img>
                    </div>
                </div>
                <div className="840px:block lg:hidden 1024px:hidden flex flex-col gap-10 justify-between align-middle h-full 640px:h-[1000px]">
                    {/*<div className="w-1/2 640px:w-full 640px:h-[340px] 840px:w-full">*/}
                    {/*<div className="h-1/3">*/}
                    <img alt="image" className="h-1/3 bg-no-repeat object-contain bg-center" src={pastedition_2023_2.src}></img>
                    {/*</div>*/}
                    {/*<div className="w-1/2 640px:w-full 640px:h-[340px] 840px:w-full">*/}
                    {/*<div className="h-1/3">*/}
                    <img alt="image" className="h-1/3 bg-no-repeat object-contain bg-center" src={pastedition_2023_3.src}></img>
                    {/*</div>*/}
                    {/*<div className="640px:w-full 640px:h-[340px]">*/}
                    {/*<div className="h-1/3">*/}
                    <img alt="image" className="h-1/3 bg-no-repeat object-contain" src={pastedition_2023_1.src}></img>
                    {/*</div>*/}
                </div>

            </div>,
        paragraph: "In 2023, the event moved to Fairmont Copacabana Hotel, Rio de Janeiro, Brazil.",
        videos: <div className="video-container flex justify-center align-middle">
            <iframe
                className=""
                // style={{
                //     aspectRatio: 16/9,
                //     width: "100%"
                // }}
                width="853"
                height="480"
                src={`https://www.youtube.com/embed/OAJzM2oVKQ0`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </div>
    },
    {
        year: "2022",
        html: <div className="flex gap-8 1280px:gap-6 1024px:gap-4 640px:gap-8 h-[600px] 1440px:h-[550px] 1280px:h-[510px] 1024px:h-[370px] 640px:h-auto 640px:flex-col">
            <div className="w-1/3 bg-center 640px:w-full 640px:h-[340px] past__edition__1"></div>
            <div className="w-1/3 bg-center 640px:w-full 640px:h-[340px] past__edition__2"></div>
            <div className="w-1/3 bg-center 640px:w-full 640px:h-[340px] past__edition__3"></div>
        </div>,
        paragraph: "ZENCON first edition was held in Playa del Carmen, México.",
        videos: <SlideShow dataType="youtube" dataSource={ytVideos2022}/>
    },
    // {
    //     year: "2024",
    //     html: <div className="flex gap-8 1280px:gap-6 1024px:gap-4 640px:gap-8 h-[600px] 1440px:h-[550px] 1280px:h-[510px] 1024px:h-[370px] 640px:h-auto 640px:flex-col">
    //         <div className="w-1/3 bg-center 640px:w-full 640px:h-[340px] venue1"></div>
    //         <div className="w-1/3 bg-center 640px:w-full 640px:h-[340px] venue2"></div>
    //         <div className="w-1/3 bg-center 640px:w-full 640px:h-[340px] venue3"></div>
    //     </div>,
    //     paragraph: "In 2024, text text text text text text.",
    //     videos: <SlideShow dataType="youtube" dataSource={ytVideos}/>
    // }
]

function PastEditions() {
    return (
        <div className="container mx-auto 1600px:px-[30px] 1440px:px-[80px] 1280px:px-[70px] 1024px:px-[30px] 767px:px-[20px] 640px:px-[80px] 480px:px-[30px] 640px:max-w-[100%]">
            <div className="text-center w-[40%] 1024px:w-[70%] 480px:w-[82%] mx-auto">
                <GlobalContent GlobalContentHeading="Past editions"/>
            </div>
            <div className="flex flex-col gap-20">
                {pastEditions.map((edition, index) => (
                    <div key={index} className="pb-10">
                        <div className="border-b-4 border-[#87FF4F]">
                            <Heading level="1">{edition.year}</Heading>
                        </div>
                        <div className="">
                            <GlobalContent Paragraph1={edition.paragraph}/>
                        </div>
                        <div className="pb-20">
                            {edition.videos}
                        </div>
                        <div>
                            {edition.html}
                        </div>
                        <div>
                            {edition.images}
                        </div>
                    </div>))}
            </div>
        </div>
    )
}

export default PastEditions