import Heading from "../Atoms/Headings/Heading";

function PastLocations() {
    return (
        <div className="rio-left-bg 1024px:bg-none">
            <div className="container bg-grad bg-[10%] px-10 bg-gradient mx-auto 1024px:px-0 640px:max-w-[100%] 640px:px-[20px]">
                <div className="max-w-[667px]">
                    <Heading level="3">Past Locations</Heading>
                </div>
                <div className="bg-pastlocations flex flex-col justify-start mx-0 gap-[36px] pt-[100px] pb-[190px] pl-28 800px:bg-contain 640px:p-0 800px:pt-20 800px:px-10 1024px:text-center
                    640px:gap-[16px] 640px:px-[20px] 640px:pt-[20px] 640px:pb-[150px]  480px:px-[20px] 480px:pt-[20px] 480px:pb-[80px] 480px:bg-bottom">
                    <div className="h-[220px]">
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PastLocations;
