import {nanoid} from "nanoid";
import {useUserDataContext} from "../../context/UserDataContextProvider";
import CustomButton from "../Atoms/Button/CustomButton";
// import GreenButton from "../Atoms/Button/GreenButton";
import BannerContent from "../Molecules/Content/BannerContent";
import PrizePool from "../Molecules/PrizePool/PrizePool";
import Link from "next/link";

const BannerInner =
    "flex flex-col gap-6 1024px:gap-3 items-center justify-center pt-[420px] 640px:pt-[350px] text-center";

function Banner(props) {
    const {isAuthed} = useUserDataContext();
    const redirectUrl = isAuthed ? "/dashboard" : "/authentication";
    const isButtonDisabled = true;

    return (
        <div
            className="flex items-end justify-center 1600px:px-20 1024px:px-0"
            key={nanoid()}
        >
            <div className="container 640px:max-w-[100%] 1440px:px-[30px]">
                <div className={BannerInner}>
                    <BannerContent
                        labelText="September 16 - 21, 2023"
                        headingText="Join the Global Hackathon to Shape the Future of Finance"
                        paragraphText="Are you ready to take on the challenge and compete against some of the world's most innovative developers? ZENCON is the perfect opportunity to showcase your skills and make a name for yourself in the world of blockchain and finance. The competition is fierce, and the stakes are high."
                    />
                    <PrizePool/>
                    <div className="flex gap-5 1024px:flex-col">
                        <div className="flex gap-5 1024px:flex-col">
                            {/*{isButtonDisabled ? (*/}
                            {/*  <a*/}
                            {/*    className="font-Hanson uppercase font-normal text-[16px] leading-[16.16px] text-center w-[310px] h-[52px] text-[#121212] border-2 flex justify-center items-center border-[#FF6060] bg-[#FF6060] cursor-not-allowed 1024px:w-[214px] 1024px:h-[45px] 1024px:text-[14px]"*/}
                            {/*    href="#"*/}
                            {/*  >*/}
                            {/*    Registration*/}
                            {/*  </a>*/}
                            {/*) : (*/}
                            {/*  <Link href={redirectUrl}>*/}
                            {/*    <a className="font-Hanson uppercase font-normal text-[16px] leading-[16.16px] text-center w-[310px] h-[52px] text-[#121212] border-2 flex justify-center items-center border-[#FF6060] bg-[#FF6060] hover:bg-[#fff] hover:border-[#fff] hover:cursor-pointer 1024px:w-[214px] 1024px:h-[45px] 1024px:text-[14px]">*/}
                            {/*      Registration*/}
                            {/*    </a>*/}
                            {/*  </Link>*/}
                            {/*)}*/}
                            <CustomButton href="/more-information" text="More Details"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Banner;
